/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment UserBase on User {\n    uuid\n    __typename\n  }\n": types.UserBaseFragmentDoc,
    "\n  query getUserUUID {\n    viewer {\n      ...UserBase\n    }\n  }\n": types.GetUserUuidDocument,
    "\n  query getAllInstallations {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n      }\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n": types.GetAllInstallationsDocument,
    "\n  query linearTeam {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          name\n          urlKey\n        }\n        linearTeams {\n          name\n          key\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n": types.LinearTeamDocument,
    "\n  mutation createGithubAppInstallation($installationId: String!) {\n    createGithubAppInstallation(installationId: $installationId) {\n      status\n      githubAppInstallation {\n        id\n      }\n    }\n  }\n": types.CreateGithubAppInstallationDocument,
    "\n  query getGraphqlInstallation {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n        actorLogin\n        actorType\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n": types.GetGraphqlInstallationDocument,
    "\n  mutation processLinearInstallation($code: String!) {\n    processLinearAppInstallation(code: $code) {\n      linearTeams {\n        uuid\n      }\n    }\n  }\n": types.ProcessLinearInstallationDocument,
    "\n  query getLinearInstallation {\n    viewer {\n      ...UserBase\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n": types.GetLinearInstallationDocument,
    "\n  query getLinearTeams {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          uuid\n        }\n        linearTeams {\n          uuid\n          name\n          repository {\n            uuid\n          }\n        }\n      }\n      githubAppInstallation {\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n": types.GetLinearTeamsDocument,
    "\n  mutation activateProductaInLinearTeam(\n    $linearTeamUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    activateProductaInLinearTeam(\n      linearTeamUuid: $linearTeamUuid\n      repositoryUuid: $repositoryUuid\n    ) {\n      uuid\n    }\n  }\n": types.ActivateProductaInLinearTeamDocument,
    "\n  mutation createLinearTeam(\n    $linearOrganizationUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    createProductaLinearTeam(\n      input: {\n        linearOrganizationUuid: $linearOrganizationUuid\n        repositoryUuid: $repositoryUuid\n      }\n    ) {\n      status\n      message\n    }\n  }\n": types.CreateLinearTeamDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserBase on User {\n    uuid\n    __typename\n  }\n"): (typeof documents)["\n  fragment UserBase on User {\n    uuid\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserUUID {\n    viewer {\n      ...UserBase\n    }\n  }\n"): (typeof documents)["\n  query getUserUUID {\n    viewer {\n      ...UserBase\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllInstallations {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n      }\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllInstallations {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n      }\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query linearTeam {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          name\n          urlKey\n        }\n        linearTeams {\n          name\n          key\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query linearTeam {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          name\n          urlKey\n        }\n        linearTeams {\n          name\n          key\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createGithubAppInstallation($installationId: String!) {\n    createGithubAppInstallation(installationId: $installationId) {\n      status\n      githubAppInstallation {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createGithubAppInstallation($installationId: String!) {\n    createGithubAppInstallation(installationId: $installationId) {\n      status\n      githubAppInstallation {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getGraphqlInstallation {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n        actorLogin\n        actorType\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getGraphqlInstallation {\n    viewer {\n      ...UserBase\n      githubAppInstallation {\n        id\n        actorLogin\n        actorType\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation processLinearInstallation($code: String!) {\n    processLinearAppInstallation(code: $code) {\n      linearTeams {\n        uuid\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation processLinearInstallation($code: String!) {\n    processLinearAppInstallation(code: $code) {\n      linearTeams {\n        uuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLinearInstallation {\n    viewer {\n      ...UserBase\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getLinearInstallation {\n    viewer {\n      ...UserBase\n      linearUser {\n        id\n        linearTeams {\n          repository {\n            uuid\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLinearTeams {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          uuid\n        }\n        linearTeams {\n          uuid\n          name\n          repository {\n            uuid\n          }\n        }\n      }\n      githubAppInstallation {\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getLinearTeams {\n    viewer {\n      uuid\n      linearUser {\n        linearOrganization {\n          uuid\n        }\n        linearTeams {\n          uuid\n          name\n          repository {\n            uuid\n          }\n        }\n      }\n      githubAppInstallation {\n        repositories {\n          uuid\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation activateProductaInLinearTeam(\n    $linearTeamUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    activateProductaInLinearTeam(\n      linearTeamUuid: $linearTeamUuid\n      repositoryUuid: $repositoryUuid\n    ) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation activateProductaInLinearTeam(\n    $linearTeamUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    activateProductaInLinearTeam(\n      linearTeamUuid: $linearTeamUuid\n      repositoryUuid: $repositoryUuid\n    ) {\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createLinearTeam(\n    $linearOrganizationUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    createProductaLinearTeam(\n      input: {\n        linearOrganizationUuid: $linearOrganizationUuid\n        repositoryUuid: $repositoryUuid\n      }\n    ) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createLinearTeam(\n    $linearOrganizationUuid: UUID!\n    $repositoryUuid: UUID!\n  ) {\n    createProductaLinearTeam(\n      input: {\n        linearOrganizationUuid: $linearOrganizationUuid\n        repositoryUuid: $repositoryUuid\n      }\n    ) {\n      status\n      message\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;