import { ApolloProvider } from "@apollo/client/react/context";
import {
  ApolloClient,
  createHttpLink,
  GraphQLRequest,
  InMemoryCache,
} from "@apollo/client/core";

import { setContext } from "@apollo/client/link/context";
import { useAuth } from "@clerk/clerk-react";
import { useMemo } from "react";

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_URL}/api/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ["uuid"],
    },
  },
});

const client = new ApolloClient({
  link: httpLink,
  cache,
});

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getToken } = useAuth();

  useMemo(() => {
    const authMiddleware = setContext(
      async (
        _: GraphQLRequest,
        { headers }: { headers?: Record<string, string> },
      ) => {
        const token = await getToken();
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      },
    );

    client.setLink(authMiddleware.concat(httpLink));
  }, [getToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
