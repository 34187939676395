import { ErrorAlert } from "@/components/ui/error-alert";
import { FullScreenWrapper } from "@/components/layouts/full-screen-wrapper";

interface FullScreenErrorProps {
  title?: string;
  message?: string;
  actions?: {
    label: string;
    onClick: () => void;
    variant?:
      | "default"
      | "destructive"
      | "outline"
      | "secondary"
      | "ghost"
      | "link";
  }[];
}

export const FullScreenError = ({
  title,
  message,
  actions,
}: FullScreenErrorProps) => {
  return (
    <FullScreenWrapper>
      <ErrorAlert title={title} message={message} actions={actions} />
    </FullScreenWrapper>
  );
};
