import { motion } from "framer-motion";

export const FullScreenLoading = ({ message }: { message?: string }) => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center gap-6">
      <div className="flex flex-row space-x-5">
        {[...Array<number>(3)].map((_, key) => (
          <motion.div
            key={key}
            className="w-[30px] h-[30px] border-blue-500 border-2"
            animate={{
              scale: [1, 1.5, 1.5, 1, 1],
              rotate: [0, 0, 90 * key, 180, 0],
              borderRadius: ["5%", "5%", "50%", "50%", "5%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
              delay: key * 0.5,
            }}
          />
        ))}
      </div>
      {message && (
        <p className="text-white/90 text-lg max-w-xs text-center text-balance">
          {message}
        </p>
      )}
    </div>
  );
};
