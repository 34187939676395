interface FullScreenWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const FullScreenWrapper = ({
  children,
  className = "",
}: FullScreenWrapperProps) => {
  return (
    <div
      className={`min-h-screen w-full flex items-center justify-center p-4 ${className}`}
    >
      <div className="max-w-md w-full">{children}</div>
    </div>
  );
};
