import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { ErrorMessages } from "@/config/error-messages";

interface ErrorAlertAction {
  label: string;
  onClick: () => void;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link";
}

interface ErrorAlertProps {
  title?: string;
  message?: string;
  variant?: "default" | "destructive";
  icon?: React.ReactNode;
  actions?: ErrorAlertAction[];
  className?: string;
}

export const ErrorAlert = ({
  title = "Error",
  message = ErrorMessages.GENERAL,
  variant = "destructive",
  icon = <AlertCircle className="h-4 w-4 dark:text-red-500 mb-1" />,
  actions = [],
  className = "",
}: ErrorAlertProps) => {
  const defaultAction: ErrorAlertAction = {
    label: "Try Again",
    onClick: () => window.location.reload(),
    variant: "destructive",
  };

  const finalActions = actions.length > 0 ? actions : [defaultAction];

  return (
    <Alert variant={variant} className={`${className} dark:border-red-500`}>
      <div className="flex items-center space-x-2">
        {icon}
        <AlertTitle className="dark:text-red-500 font-bold">{title}</AlertTitle>
      </div>
      <AlertDescription className="dark:text-red-500">
        {message}
      </AlertDescription>
      <div className="mt-4 flex justify-end space-x-2">
        {finalActions.map((action, index) => (
          <Button
            key={index}
            size="sm"
            variant={action.variant ?? "destructive"}
            onClick={action.onClick}
            className="dark:bg-red-500 dark:hover:bg-red-600"
          >
            {action.label}
          </Button>
        ))}
      </div>
    </Alert>
  );
};
