import { graphql, useFragment } from "@/graphql";
import { useQuery } from "@apollo/client";
import { SignedIn } from "@clerk/clerk-react";
import { ReactNode } from "react";
import { AuthenticatedUserProviderContext } from "./context";

interface ProviderProps {
  children: ReactNode;
}

const UserBaseFragment = graphql(`
  fragment UserBase on User {
    uuid
    __typename
  }
`);

const getUserUUID = graphql(`
  query getUserUUID {
    viewer {
      ...UserBase
    }
  }
`);

export const AuthenticatedUserProvider = ({ children }: ProviderProps) => {
  const { data, loading, error } = useQuery(getUserUUID);
  const user = useFragment(UserBaseFragment, data?.viewer);

  return (
    <SignedIn>
      <AuthenticatedUserProviderContext.Provider
        value={{
          userUuid: user?.uuid ?? null,
          loading: loading,
          error: error ?? null,
        }}
      >
        {children}
      </AuthenticatedUserProviderContext.Provider>
    </SignedIn>
  );
};
