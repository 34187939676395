import { createContext } from "react";

interface ProviderTypes {
  userUuid: string | null;
  loading: boolean;
  error: Error | null;
}

export const AuthenticatedUserProviderContext =
  createContext<ProviderTypes | null>(null);
